import gsap from 'gsap'

const state = {
  timeline: null
}

const $header = document.querySelector('.site-header')
const $scrollerWrapper = document.querySelector('.section-intro')

const masterTimeline = gsap.timeline()

const block2TweenOpts = { yPercent: -100 }
const block3TweenOpts = { yPercent: -200 }
const block4TweenOpts = { yPercent: -300 }

const block2Timeline = gsap.timeline()
block2Timeline.to('.intro-block-2', block2TweenOpts)

const block3Timeline = gsap.timeline()
block3Timeline.to('.intro-block-3', block3TweenOpts)

const block4Timeline = gsap.timeline()
block4Timeline.to('.intro-block-4', block4TweenOpts)

masterTimeline.add(block2Timeline, 0)
masterTimeline.add(block3Timeline, 0)
masterTimeline.add(block4Timeline, 0)
masterTimeline.pause(0)

state.timeline = masterTimeline

const handleScroll = () => {
  const headerHeight = $header.offsetHeight
  const scrollerWrapperHeight = $scrollerWrapper.offsetHeight

  const scrollerWrapperScrollPos =
    $scrollerWrapper.getBoundingClientRect().y - headerHeight

  if (scrollerWrapperScrollPos > 0) {
    state.timeline.progress(0)
    return
  }

  let progress = Math.abs(scrollerWrapperScrollPos / scrollerWrapperHeight)

  $scrollerWrapper.style.marginBottom = `calc(-200vh + ${Math.abs(scrollerWrapperScrollPos / 2)}px)`

  state.timeline.progress(progress)
}

handleScroll()

window.addEventListener('scroll', handleScroll)
